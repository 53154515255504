import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";
import FeelLikeSelector from "./FeelLikeSelector";
import Comment from "./Comment";
import { useNavigate, useParams } from "react-router-dom";
import { requestHomeworks } from "../../05_redux/HomeworkSlice";
import { updatePost } from "../../05_redux/PostSlice";
import store, { RootState } from "../../05_redux/store";
import Emotion from "./Emotion";
import HomeworkAssign from "./HomeworkAssign";
import WizardPage from "../../02_molecule/WizardPage";
import { IonContent, IonicSlides } from "@ionic/react";
import { useSelector } from "react-redux";
import EmotionRange from "./EmotionRange";

const EditPostWizard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { postId } = useParams();
    const dispatch = store.dispatch;

    const post = useSelector((root: RootState) => root.postSlice.postStore.posts).find(p => p.id === Number(postId));

    const [idx, setIdx] = useState(0);
    const [feelLike, setFeelLike] = useState(post?.feelLike);
    const [emotions, setEmotions] = useState<string[]>(Array.isArray(post?.emotions) ? post.emotions : []);
    const [comment, setComment] = useState(post?.comment);
    const [homework, setHomework] = useState(post?.homework);
    const images: string[] = [];
    const records: string[] = [];

    const swiperRef = useRef<any>(null);

    useEffect(() => {
        dispatch(requestHomeworks());
    }, [dispatch]);

    const [validationText, setValidationText] = useState<string | null>("");

    useEffect(() => {
        if ((feelLike ?? 0) < 0) {
            setValidationText(t("newPost.feelLikeError"));
        } else if (emotions.length === 0) {
            setValidationText(t("newPost.errorEmotions"));
        } else if ((emotions.map((x) => x.split(':')).map((x) => x[1] === '0').find((x) => x === true))) {
            setValidationText(t("newPost.errorEmotionsRange"));
        } else if ((comment?.length ?? 0) < 3) {
            setValidationText(t("newPost.descriptionError"));
        } else {
            setValidationText(null);
        }
    }, [feelLike, emotions, comment, t])

    const saveEnable =
        (feelLike ?? 0) >= 0 &&
        emotions.length > 0 &&
        (comment?.length ?? 0) >= 3 &&
        !(emotions.map((x) => x.split(':')).map((x) => x[1] === '0').find((x) => x === true));

    const save = () => {
        if (!post) return;
        dispatch(updatePost({
            id: post.id,
            comment: comment || "",
            feelLike: feelLike ?? 0,
            emotions,
            images,
            records,
            postDate: post?.postDate,
            homework: homework ? { "__type": "Pointer", className: "Homework", objectId: homework } : undefined
        })).then(res => {
            navigate("/post/" + post?.id);
        });
    };

    const next = () => {
        if (idx !== pages.length - 1) {
            if (!swiperRef.current) return;
            swiperRef.current.swiper.slideNext();
            setIdx(idx + 1);
        }
    };

    const back = () => {
        if (idx !== 0) {
            if (!swiperRef.current) return;
            swiperRef.current.swiper.slidePrev();
            setIdx(idx - 1);
        }
    };

    const clickOnDots = (id) => {
        if (!swiperRef.current || id === idx) return;
        swiperRef.current.swiper.slideTo(id);
        setIdx(id);
    };

    const cancel = () => {
        navigate("/post/" + post?.id);
    };

    const getEmotionLabel = (e: string) => {
        return e.split(":")[0];
    };

    const getEmotionValue = (e: string) => {
        return Number(e.split(":")[1]);
    };

    const setEmotionValue = (e: string, value: number) => {
        const newEmotions: string[] = [...emotions];
        const index = newEmotions.findIndex((ee) => ee === e);
        newEmotions[index] = getEmotionLabel(e) + ":" + value;
        setEmotions(newEmotions);
    };

    const pages = [
        {
            title: t("newPost.feelLikeTitle"),
            content: <FeelLikeSelector feelLike={feelLike ?? 0} setFeelLike={setFeelLike} />,
        },
        {
            title: t("newPost.emotionTitle"),
            content: <Emotion emotions={emotions} setEmotions={setEmotions} />,
        },
        {
            title: t("newPost.intensityTitle"),
            content: (
                <EmotionRange
                    emotions={emotions}
                    setEmotionValue={setEmotionValue}
                    getEmotionLabel={getEmotionLabel}
                    getEmotionValue={getEmotionValue}
                />
            ),
        },
        {
            title: t("newPost.commentTitle"),
            content: <Comment comment={comment || ""} setComment={setComment} />,
        },
        {
            title: t("newPost.homeworkTitle"),
            content: <HomeworkAssign initialHomework={homework?.id ?? 0} setHomework={setHomework} />,
        },
    ];

    return (
        <IonContent>
            <Swiper
                ref={swiperRef}
                modules={[IonicSlides]}
                spaceBetween={50}
                slidesPerView={1}
                initialSlide={idx}
                allowTouchMove={false}
            >
                {pages.map((p, i) => (
                    <SwiperSlide key={i}>
                        <WizardPage
                            mainWizardTitle={t("newPost.editMainWizardTitle")}
                            save={save}
                            next={next}
                            back={back}
                            setPage={clickOnDots}
                            cancel={cancel}
                            saveEnable={saveEnable}
                            wizardProgress={i / (pages.length - 1)}
                            wizardMax={pages.length}
                            title={p.title}
                            validationText={validationText}
                        >
                            {p.content}
                        </WizardPage>
                    </SwiperSlide>
                ))}
            </Swiper>
        </IonContent>
    );
};

export default EditPostWizard;