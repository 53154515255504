import React from "react";
import classes from "./FeelLikeSelector.module.scss";
import { FaRegAngry, FaRegGrinStars, FaRegMeh } from "react-icons/fa";

const FeelLikeSelector = ({ feelLike, setFeelLike }: Props) => {
  const options = [
    { id: "feelLike-happy", icon: <FaRegGrinStars /> },
    { id: "feelLike-neutral", icon: <FaRegMeh /> },
    { id: "feelLike-angry", icon: <FaRegAngry /> },
  ];

  const getTransformValue = () => {
    if (feelLike === -1) {
      return "translateX(0)";
    }
    return `translateX(calc(50% - ${feelLike * 190}px - 90px))`;
  };

  const getWidthValue = () => {
    if (feelLike === -1) {
      return "auto";
    }
    return "300px!important";
  };

  return (
    <div className={classes.feellikeselectorContainer}>
      <div className={classes.feellikeselectorWrapper} style={{ transform: getTransformValue(), width: getWidthValue() }}>
        {options.map((option, index) => (
          <span
            id={option.id}
            key={index}
            className={feelLike === index ? classes.feellikeselectorOptionCheck : classes.feellikeselectorOption}
            onClick={() => setFeelLike(index)}
          >
            {option.icon}
          </span>
        ))}
      </div>
    </div>
  );
};

interface Props {
  feelLike: number;
  setFeelLike: Function;
}

export default FeelLikeSelector;
