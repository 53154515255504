import React, { useEffect, useState } from 'react';
import classes from './HomeworkAssign.module.scss';
import store, { RootState } from '../../05_redux/store';
import { HomeworkEntity } from '../../06_utils/db/entity/homeworkEntity';
import { useSelector } from 'react-redux';
import { requestHomeworks } from '../../05_redux/HomeworkSlice';

const HomeworkAssign = ({ initialHomework, setHomework }: Props) => {
    const dispatch = store.dispatch;
    const homeworks: HomeworkEntity[] = useSelector((root: RootState) => root.homeworkSlice.homeworkStore.homeworks);
    const [homework, setHomeworkState] = useState<string>(initialHomework);

    useEffect(() => {
        dispatch(requestHomeworks());
    }, [dispatch]);

    const handleHomeworkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedHomework = event.target.value;
        setHomeworkState(selectedHomework);
        setHomework(selectedHomework);
    };

    return (
        <div className={classes.commentContainer}>
            <div className={classes.homeworkAssignContainer}>
                {homeworks.map((h) => (
                    <label key={h.id} className={`${classes.homeworkAssignOption} ${String(homework) === String(h.id) ? classes.selected : ''}`}>
                        <input
                            type="radio"
                            name="homework"
                            value={String(h.id)}
                            checked={String(homework) === String(h.id)}
                            onChange={handleHomeworkChange}
                        />
                        <span className={classes.radioLabel}>{h.title}</span>
                    </label>
                ))}
            </div>
        </div>
    );
};

interface Props {
    initialHomework: string;
    setHomework: (value: string) => void;
}

export default HomeworkAssign;