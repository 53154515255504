import React from 'react';
import classes from './AddHomeworkManualy.module.scss';
import { useTranslation } from 'react-i18next';
import { IonTextarea } from '@ionic/react';

const AddHomeworkManualy = ({ title, descr, setTitle, setDescr }: Props) => {
    const { t } = useTranslation();

    return (
        <div className={classes.commentContainer}>
            <IonTextarea
                id="homework-title-input"
                value={title}
                rows={1}
                placeholder={t('newHomework.titlePlaceholder')}
                onIonChange={(event) => setTitle(event.detail.value)}
                className={classes.textarea}
            />
            <IonTextarea
                id="homework-descr-input"
                value={descr}
                rows={10}
                placeholder={t('newHomework.descrPlaceholder')}
                onIonChange={(event) => setDescr(event.detail.value)}
                className={classes.textarea}
            />
        </div>
    );
};

interface Props {
    title: string;
    descr: string;
    setTitle: Function;
    setDescr: Function;
}

export default AddHomeworkManualy;