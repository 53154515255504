import React from 'react';
import { IonDatetime } from '@ionic/react';
import classes from './HomeworkDate.module.scss';

const HomeworkDate = ({ homeworkDate, setHomeworkDate }: Props) => {
  return (
    <div className={classes.DeadlineContainer}>
      <IonDatetime
        id="homework-date-picker"
        presentation="date"
        className={classes.DeadlineInput}
        value={homeworkDate.toISOString()}
        onIonChange={(event) => setHomeworkDate(new Date(event.detail.value!))}
      />
    </div>
  );
};

interface Props {
  homeworkDate: Date;
  setHomeworkDate: (date: Date) => void;
}

export default HomeworkDate;